<template>
  <div class="referral-page-container" v-if="!isLoading">
    <header-container></header-container>
    <div class="auth-el on-mobile">
      <referral-card
          :referralConfig="referralConfig"
          :showDefaultText="showDefaultText">
        <v-form class="form-style"
                v-model="isValid"
        >
          <div class="card-description">
            {{ $t("referralReport.subTitle") }}
          </div>
          <div class="referral-subtext" style="margin-top: 25px;">
            {{ $t("referralReport.referrerInputTitle") }}
          </div>
          <div class="simple-row referral-row">
            <v-text-field
                v-model="referralReportForm.referrerWallet"
                placeholder="01XXXXXXXXX"
                :label="$t('referralReport.referrerInputLabel')"
                outlined
                dense
                :rules="inputRules.walletRules"
                style="width: 290px !important;"
                type="text"
                required
                class="referral-input inputNumber inputNumber-container"
                single-line
                @input="validateInput"
            ></v-text-field>
          </div>
          <v-btn
              class="referral-otp-button"
              style="width: 100% !important; font-size: 21px; margin-bottom: 20px;"
              color="primary"
              @click="sendOtpClicked"
              v-if="otpResendSeconds <= 0"
              :disabled="!isValid"
          >
            {{ $t("referralReport.sendOtpButtonLabel") }}
          </v-btn>

          <div class="otp-resend-text" v-if="otpResendSeconds > 0">
            {{ $t("referralReport.resendOtpText", [otpResendSeconds]) }}
          </div>

          <template v-if="hasOtpBeenSent">
            <div class="referral-subtext">
              {{ $t("referralReport.otpInputTitle") }}
            </div>
            <div class="simple-row referral-row">
              <v-text-field
                  v-model="referralReportForm.otp"
                  placeholder="XXXXXX"
                  :label="$t('referralReport.otpInputLabel')"
                  outlined
                  dense
                  :rules="inputRules.otpRules"
                  style="width: 290px !important;"
                  type="text"
                  required
                  class="referral-input inputNumber inputNumber-container"
                  single-line
                  @input="validateOtpInput"
              ></v-text-field>
            </div>

            <div class="referral-subtext">
              {{ $t("referralReport.emailInputTitle") }}
            </div>
            <div class="simple-row referral-row">
              <v-text-field
                  v-model="referralReportForm.referrerEmail"
                  placeholder="name@company.com"
                  :label="$t('referralReport.emailInputLabel')"
                  outlined
                  dense
                  :rules="inputRules.emailRules"
                  style="width: 290px !important;"
                  type="text"
                  required
                  class="referral-input"
                  single-line
                  oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="100"
              ></v-text-field>
            </div>

            <v-btn
                class="referral-submit-button"
                style="width: 100% !important; font-size: 21px;"
                color="primary"
                @click="confirmOtpClicked"
                :disabled="!isValid"
            >{{ $t("referralReport.sendEmailButtonLabel") }}
            </v-btn>
          </template>

          <!-- <footer-container></footer-container> -->

        </v-form>
      </referral-card>
    </div>
  </div>
</template>

<script>
import ReferralCard from "./ReferralCard.vue";
import HeaderContainer from "@/components/referral/HeaderContainer";
import {DIGITS_ONLY_INPUT_RULE, PHONE_NUMBER_RULE, EMAIL_ADDRESS_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "ReferralReport",
  components: {
    ReferralCard,
    HeaderContainer,
  },
  created() {
    this.getReferralConfig();
  },
  data() {
    return {
      referralReportForm: {
        referrerWallet: "",
        otp: "",
        referrerEmail: "",
      },
      inputRules: {
        walletRules: [
          v => !!v || this.$i18n.t('referralReport.wallet_entry_form.wallet_mandatory'),
          v => (v && v.length === 11) || this.$i18n.t('referralReport.wallet_entry_form.wallet_length'),
          v => PHONE_NUMBER_RULE.test(v) || this.$i18n.t('referralReport.wallet_entry_form.wallet_valid'),
        ],
        emailRules: [
          v => (v && v.length >= 4) || this.$i18n.t('referralReport.email_length'),
          v => this.emailRegex.test(v) || this.$i18n.t('referralReport.email_valid'),
        ],
        otpRules: [
          v => !!v || this.$i18n.t('referralReport.otp_entry_form.otp_blank'),
          v => (v && v.length === 6) || this.$i18n.t('referralReport.otp_entry_form.otp_length')
        ]
      },
      isValid: true,
      hasOtpBeenSent: false,
      lastOtpSendTime: 0,
      otpResendSeconds: 0,
      isLoading: false,
      showDefaultText: true,
      referralConfig: {},
    };
  },
  methods: {
    updateOtpResendDelay() {
      const THRESHOLD_SECONDS = 120;
      let diff = (Date.now() - this.lastOtpSendTime) / 1000;
      if (diff > THRESHOLD_SECONDS) {
        this.otpResendSeconds = 0;
        return;
      }
      this.otpResendSeconds = THRESHOLD_SECONDS - Math.round(diff);
      setTimeout(() => this.updateOtpResendDelay(), 1000);
    },
    async sendOtpClicked() {
      try {
        this.$feedback.showLoading();
        await this.$cppClientExternalServices.post("referral/report/otp/request", this.referralReportForm);
        this.$feedback.hideLoading();
        let message = this.$t("referralReport.otpSent", this.referralReportForm);
        this.$feedback.showSuccessMessage(message);
        this.hasOtpBeenSent = true;
        this.lastOtpSendTime = Date.now();
        this.updateOtpResendDelay();
      } catch ({response}) {
        this.$feedback.hideLoading();
        await this.$feedback.showFailed(response);
      }
      this.hasOtpBeenSent = true;
      this.lastOtpSendTime = Date.now();
      this.updateOtpResendDelay();
      this.$feedback.hideLoading();
    },
    async confirmOtpClicked() {
      try {
        this.$feedback.showLoading();
        await this.$cppClientExternalServices.post(
            "referral/report/otp/confirm",
            this.referralReportForm
        );
        this.$feedback.hideLoading();
        let message = this.$t("referralReport.reportSent", this.referralReportForm);
        this.$feedback.showSuccessMessage(message);
        this.resetForm();
      } catch ({response}) {
        await this.$feedback.showFailed(response);
      }
    },
    stripCountryCode(shortCode) {
      if (shortCode.slice(0, 1) === "+") {
        return (shortCode = shortCode.substring(3));
      } else return shortCode;
    },
    resetForm() {
      this.hasOtpBeenSent = false;
      this.lastOtpSendTime = 0;
      this.otpResendSeconds = 0;
      this.referralReportForm.otp = "";
      this.referralReportForm.referrerWallet = "";
      this.referralReportForm.referrerEmail = "";
    },
    validateInput() {
      const maxLength = 11;

      this.$nextTick(() => {
        this.referralReportForm.referrerWallet = this.referralReportForm.referrerWallet.replace(DIGITS_ONLY_INPUT_RULE, '')
      })

      if (this.referralReportForm.referrerWallet.length > maxLength) {
        this.$nextTick(() => {
          this.referralReportForm.referrerWallet = this.referralReportForm.referrerWallet.slice(0, maxLength);
        })
      }
    },
    validateOtpInput() {
      const otpMaxLength = 6;

      this.$nextTick(() => {
        this.referralReportForm.otp = this.referralReportForm.otp.replace(DIGITS_ONLY_INPUT_RULE, '')
      })

      if (this.referralReportForm.otp.length > otpMaxLength) {
        this.$nextTick(() => {
          this.referralReportForm.otp = this.referralReportForm.otp.slice(0, otpMaxLength);
        })
      }
    },
    async getReferralConfig() {
      const reqObj = {};
      this.$feedback.showLoading();
      this.isLoading = true;
      try {
        const {data} = await this.$cppClientExternalServices.post("referral/configuration/get", reqObj);
        this.$feedback.hideLoading();
        this.isLoading = false;

        if (data.referralCampaignConfig) {
          this.showDefaultText = false
          const parsedData = JSON.parse(data.referralCampaignConfig);
          this.referralConfig = parsedData;
        }
      } catch ({response}) {
        this.showFallBackData = true;
        this.$feedback.hideLoading();
        this.isLoading = false;
      }
    }
  },
  computed: {
    emailRegex() {
      return EMAIL_ADDRESS_RULE;
    },
  }
};
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;
$mobileS: 370px;

.referral-page-container {
  height: 100vh;
  //background: rgb(2, 0, 36);
  //background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.5816701680672269) 35%, rgba(210, 247, 255, 1) 100%);
  background-color: #ffffff;
  // background-image: url('../../assets/image/referral/landing-bg.png');
  // background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $MedW) {
    width: 100%;
    background-size: 100% 100%;
  }
  @media (max-width: $mobileW) {
    // background-image: url("../../assets/image/referral/landing-bg-small.png");
    // background-size: cover;
  }

  .body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //height: 80%;
    flex-direction: column;
  }
}

.card-description {
  font-family: Roboto;
  font-size: 18px;
  text-align: center;
  color: #707070;
  margin-bottom: 15px;
}

.form-style {
  width: 330px;
  display: inline-block;
}

.referral-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 80%;
  flex-direction: column;

  .referral-title {
    font-family: Roboto;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #707070;
    margin-bottom: 40px;
  }

  .referral-subtext {
    text-align: left;
    color: #707070;
  }

  .referral-row {
    max-width: 400px;
  }

  .referral-input {
    margin-top: 8px;
  }

  .referral-link-container {
    margin-top: 20px;

    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;

    .bolder {
      font-weight: bold;
      color: #e2136e;
    }
  }

  .referral-submit-button {
    margin-top: 20px;

    min-width: 100%;
    width: 100% !important;
    max-width: 100%;
    height: 69px;
    padding: 13px 69px 12px 70px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #e2136e;

    font-family: Roboto;
    font-size: 33px;
    font-weight: 500;
  }

  .referral-otp-button {
    margin-top: 0;
    min-width: 100%;
    width: 100% !important;
    max-width: 100%;
    height: 75px;
    padding: 13px 69px 12px 70px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #e2136e;

    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: normal;
    // margin-left: 12px;
    // margin-top: -22px;
  }

  .referral-checkbox {
    max-width: 400px;
  }

  .otp-resend-text {
    color: #707070;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  @media (max-width: $mobileW) {
    align-items: center;

    .referral-submit-button {
      width: 250px;
      font-size: 20px;
      height: 40px;
    }

    .referral-title {
      font-size: 36px;
      text-align: center;
    }

    .referral-link-container {
      font-size: 24px;
      margin-left: 2px;
    }

    .referral-otp-button {
      margin-bottom: 10px;
      margin-left: 0px;
    }

    .otp-resend-text {
      padding: 18px;
      text-align: center;
    }

    .card-description {
      font-size: 18px;
    }

    .form-style {
      width: 312px;
    }
  }
}

@media (max-width: $mobileW) {
  .on-mobile {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: $mobileS) {
  .form-style {
    width: 312px;
  }
}
</style>
